<template>
  <v-menu 
    offset-y
    open-on-hover
    style="background-color: #FFF;"
    content-class="menu-list"
  >
    <template v-slot:activator="{ on, attrs }">
      <span
        v-bind="attrs"
        v-on="on"
        class="selected_types_lancamentos"
      >{{ selectedTipoVisualizacao.text }}</span>
    </template>
    <v-list
      flat
      dense
    >

      <v-list-item
        v-for="(tipoVisualicao, i) in (tiposVisualizacoes || [])"
        :key="i"
      >
      
        <v-list-item-content>
          <v-list-item-title class="title-list" v-text="tipoVisualicao.text" @click="setVisualizacao(tipoVisualicao)"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "OrganizzeMenuCategoriasVisualizacao",
  props: {
    tiposVisualizacoes: {
      type: Array  
    },

    selectedTipoVisualizacao: {
      type: Object,
      required: true
    }
  },

  methods: {
    setVisualizacao(tipoVisualizacao) {
      this.$emit('setTipoVisualizacao', tipoVisualizacao)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/helpers/_variables.scss';
.menu-list {
  background-color: #FFF;

  .title-list {
    cursor: pointer;
  }
}

.selected_types_lancamentos {
  color: $colorPrimaryDarken;
}
</style>